



























import { Component, Prop } from 'vue-property-decorator';
import Vue from 'vue';
import styleVariables from '../../styles/export.scss';

@Component({
  name: 'BaseAddButton'
})
export default class BaseAddButton extends Vue {
  @Prop({ default: 'Add' }) text !: string;

  @Prop({ default: '300' }) cardWidth!: string;

  @Prop({ default: '85' }) cardHeight!: string;

  styleVariables = styleVariables;

  ruId(): string {
    const ruidString = `button-${this.text}`;
    return ruidString.replace(/\s+/g, '-').toLowerCase();
  }
}
