














































































































import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { ActionMethod } from 'vuex';
import { debounce } from 'lodash';
import UserSearchParams from '@improve/common-utils/src/types/UserSearchParams';
import User from '@improve/common-utils/src/model/User';
import Team from '@improve/common-utils/src/model/Team';
import BaseTeamMemberCard from '../widgets/BaseTeamMemberCard.vue';
import BaseModal from '../widgets/BaseModal.vue';
import BaseButton from '../widgets/BaseButton.vue';
import BaseTextInput from '../core/BaseTextInput.vue';

@Component({
  name: 'BaseUserSelectorModal',
  components: {
    BaseTeamMemberCard,
    BaseTextInput,
    BaseModal,
    BaseButton
  }
})
export default class BaseUserSelectorModal extends Vue {
  @Getter allTeamsById?: (id: string) => Team;

  @Prop({ default: false }) readonly show!: boolean;

  @Prop({ default: false }) readonly hideSelected!: boolean;

  @Prop({ default: false }) readonly isMember!: boolean;

  @Prop({ default: null }) readonly title!: string;

  @Prop({ default: () => [] }) readonly preSelectedUsers!: Array<User>;

  @Action fetchUsers!: ActionMethod;

  private users: Array<User> | null = null;

  private search = '';

  private searchInProgress = false;

  private selectedUsers: Array<User> = [];

  private selectedUsersById: Map<string, User> = new Map<string, User>();

  private debounceSearchInput = debounce((event: string) => {
    this.fetchUsersByName(event);
  }, 300);

  get filteredUsers(): Array<User> {
    if (!this.users) {
      return [];
    }
    if (this.hideSelected) {
      return this.users.filter((_) => !this.selectedUsersById.has(_.id!));
    }
    return this.users;
  }

  created(): void {
    this.setPreselectedUsers();
    this.setSelectedUsersById();
  }

  setPreselectedUsers(): void {
    this.selectedUsers = [...this.preSelectedUsers];
  }

  setSelectedUsersById(): void {
    this.selectedUsersById = new Map<string, User>(this.selectedUsers.map(
      (_: User) => [_.id!, _]
    ));
  }

  fetchUsersByName(searchName: string): void {
    const search = new UserSearchParams();
    search.name = searchName;
    this.searchInProgress = true;
    this.fetchUsers({ params: search }).then((users: Array<User>) => {
      this.users = users;
      this.searchInProgress = false;
    });
  }

  addUser(user: User): void {
    if (this.selectedUsersById.has(user.id!)) {
      return;
    }
    this.selectedUsers.push(user);
    this.setSelectedUsersById();
  }

  removeSelectedMember(user: User): void {
    this.selectedUsers = this.selectedUsers.filter((_) => _.id !== user.id);
    this.setSelectedUsersById();
    if (!this.isMember) {
      this.$emit('onRemoveOwner', user);
    } else {
      this.$emit('onRemoveMember', user);
    }
  }

  @Watch('show')
  onClose(newValue: boolean): void {
    if (!newValue) {
      this.setPreselectedUsers();
      this.setSelectedUsersById();
    }
  }

  userUnitName(member: User): string {
    const unit = member.unit && this.allTeamsById && this.allTeamsById(member.unit);
    return unit ? `${unit.name}` : '';
  }

  isHighlighted(user: User): boolean {
    return this.selectedUsers.filter((selected: User) => selected.id === user.id).length > 0;
  }
}
