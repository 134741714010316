import { render, staticRenderFns } from "./BaseAddButton.vue?vue&type=template&id=c17e6ef0&scoped=true&"
import script from "./BaseAddButton.vue?vue&type=script&lang=ts&"
export * from "./BaseAddButton.vue?vue&type=script&lang=ts&"
import style0 from "./BaseAddButton.vue?vue&type=style&index=0&id=c17e6ef0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c17e6ef0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard})
