




















































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { MenuOption } from '@improve/common-utils/src/types/MenuOption';
import styleVariables from '../../styles/export.scss';
import BaseMenu from './BaseMenu.vue';
import BaseUserAvatar from './BaseUserAvatar.vue';
import BasePill from './BasePill.vue';

@Component({
  name: 'BaseTeamMemberCard',
  components: {
    BaseMenu,
    BaseUserAvatar,
    BasePill
  }
})

export default class BaseTeamMemberCard extends Vue {
  clicked = false;

  @Prop({ default: null }) avatar!: string;

  @Prop({ default: 46 }) avatarSize!: number;

  @Prop({ default: null }) title!: string;

  @Prop({ default: null }) subtitle!: string;

  @Prop({ default: '300' }) cardWidth!: string;

  @Prop({ default: '85' }) cardHeight!: string;

  @Prop({ default: false }) showRemoveButton!: boolean;

  @Prop({ default: false }) isSmallCard!: boolean;

  @Prop({ default: () => [] }) readonly menuOptions!: Array<MenuOption>;

  @Prop({ default: false }) isHighlighted!: boolean;

  @Prop({ default: null }) role!: string;

  styleVariables = styleVariables;
}
